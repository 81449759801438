import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import Helmet from 'react-helmet'
import { isEmpty } from 'lodash'

import { buildUserAttributes } from '@/utils/launchDarkly'
import { Content } from '@/components/layout/Content'
import { Wrapper } from '@/components/layout/Wrapper'
import { GlobalSidebar } from '@/components/navigation/GlobalSidebar/GlobalSidebar'
import { unauthenticateUser } from '@/reducers/auth/currentUser.actions'
import { loginUser } from '@/reducers/auth/currentUser.redux'
import { fetchCurrentOrganization } from '@/reducers/auth/currentOrganization.actions'
import { AlertSidebar } from '@/views/RealtimeCoaching/Alerts/AlertSidebar/AlertSidebar'
import { initializePendo } from '@/utils/initializePendo'

import faviconLight from '../assets/images/favicon-light.png'
import faviconDark from '../assets/images/favicon-dark.png'

const ErrorWrapper = React.lazy(() => import('../components/layout/ErrorWrapper'))

const ProtectedRouteComponent = ({
  component: Component,
  adminOnly,
  dataGrid,
  qaRoute,
  qaCopilotRoute,
  componentProps,
  ldClient,
  pageTitle,
  ...rest
}) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [checkedAuth, setCheckedAuth] = useState(false)
  const [checkedOrg, setCheckedOrg] = useState(false)
  const {
    token,
    organizationid: currentOrganizationId,
    real_time_management_access: deprecatedRtcAccess,
    realtime_coaching_access: userRealtimeAccess,
    org_realtime_coaching_access: orgRealtimeAccess,
    edit_qa: qaAccess,
    edit_qa_copilot: qaCopilotAccess,
    hierarchy_manager: isHierarchyManager,
    own_organization_id: ownOrganizationId,
  } = useSelector((state) => state.currentUser)
  const currentOrganization = useSelector((state) => state.currentOrganization)
  const { customBranding } = useSelector((state) => state.customBranding)

  const isBaltoAdmin = currentOrganizationId === 1
  const faviconUrl = customBranding?.faviconUrl
  const displayName = customBranding?.customBrandingDisplayName || 'Balto Cloud'
  const title = `${displayName} ${pageTitle ? `| ${pageTitle}` : ''}`
  const childOrgSelected = isHierarchyManager && currentOrganizationId !== ownOrganizationId

  useEffect(() => {
    if (!isEmpty(currentOrganization)) {
      setCheckedOrg(true)
    }

    if (!token) {
      const user = JSON.parse(localStorage.getItem('User'))

      if (user) {
        dispatch(loginUser(user))
        initializePendo()

        if (ldClient) {
          ldClient.identify(buildUserAttributes(user))
        }
      } else {
        dispatch(unauthenticateUser({ history, location, ldClient }))
      }
    } else {
      window.addEventListener('load', initializePendo)
    }

    setCheckedAuth(true)
  }, [])

  useEffect(() => {
    const authenticateOrganization = async () => {
      await dispatch(fetchCurrentOrganization(currentOrganizationId))

      setCheckedOrg(true)
    }

    if (token && isEmpty(currentOrganization)) {
      authenticateOrganization()
    }
  }, [token, currentOrganization])

  // Don't render component before auth is checked
  if (!checkedAuth) {
    return <Loader active data-testid="protected-route-loading" />
  }

  // Redirect admin routes if not an admin
  if (adminOnly && !isBaltoAdmin) {
    return <Redirect to="/" />
  }

  // Redirect permissionless QA routes
  if (qaRoute && !qaAccess && !isBaltoAdmin) {
    return <Redirect to="/" />
  }

  // Redirect permissionless QA Copilot routes
  if (qaCopilotRoute && !qaCopilotAccess && !isBaltoAdmin) {
    return <Redirect to="/" />
  }

  if (!checkedOrg) {
    return <Loader active data-testid="protected-route-loading" />
  }

  return (
    <Route {...rest}>
      <Helmet>
        <link href={faviconUrl || faviconLight} rel="icon" media="(prefers-color-scheme: light)" />
        <link href={faviconUrl || faviconDark} rel="icon" media="(prefers-color-scheme: dark)" />
        <title>{title}</title>
      </Helmet>
      <Wrapper>
        <GlobalSidebar />
        <Content dataGrid={dataGrid}>
          <ErrorWrapper />
          <Component location={location} history={history} {...componentProps} />
        </Content>
        {/* user + org must have access, and need to hide for hierarchy managers impersonating child org (no support for org hierarchy yet) */}
        {(userRealtimeAccess || deprecatedRtcAccess) && orgRealtimeAccess && !childOrgSelected && (
          <AlertSidebar />
        )}
      </Wrapper>
    </Route>
  )
}

export const ProtectedRoute = withLDConsumer()(ProtectedRouteComponent)
