import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { Button, Popup, Segment } from 'semantic-ui-react'
import { isEmpty, truncate } from 'lodash'
import {
  IconArrowLeft,
  IconCircleCheckFilled,
  IconCircleDashedCheck,
  IconTrash,
} from '@tabler/icons-react'

import {
  deletePlaylistCall,
  fetchPlaylist,
  patchPlaylist,
  patchPlaylistCall,
} from '@/reducers/playlists/playlists.actions'
import { pauseCallAudio } from '@/reducers/audio/audio.redux'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { formatTime, reorder } from '@/utils/helpers'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import ConfirmForm from '@/components/forms/ConfirmForm'
import { DEFAULT_CALL_COLUMNS } from '@/views/Calls/callColumns'
import { auditColumns } from '@/utils/constants'
import { AudioDrawer } from '@/components/audio/AudioDrawer'
import { AudioPlayerButton } from '@/components/audio/AudioPlayerButton'
import NoData from '@/components/NoData'

import { PlaylistName } from './PlaylistName'
import { EditPlaylistCallAlias } from './EditPlaylistCallAlias'

export const PlaylistCallsContent = ({ isCoachingPage }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { playlist, playlistChanges, loading } = useSelector((state) => state.playlists)
  const { organizationid: currentUserOrgId } = useSelector((state) => state.currentUser)
  const tableIndex = 'call_id'
  // TODO: This can be removed once Balto Admins/Hierarchy Managers can edit other orgs
  const canEditPlaylist = currentUserOrgId === playlist?.organization_id

  const handleReorder = async (result, activePage, rowsPerPage) => {
    const { source, destination } = result
    if (!destination) return

    const numberOfRowsToOffset = activePage * rowsPerPage - rowsPerPage
    const offset = numberOfRowsToOffset

    let reorderedCalls = []
    if (activePage === 1) {
      reorderedCalls = reorder(playlist.playlist_calls, source.index, destination.index)
    } else {
      reorderedCalls = reorder(
        playlist.playlist_calls,
        offset + source.index,
        offset + destination.index
      )
    }

    const changedCalls = reorderedCalls
      .map((call, index) => {
        if (call.order_id !== index) {
          return {
            call_id: call.call_id,
            is_flagged: call.is_flagged,
            order_id: index,
          }
        }
        return null
      })
      .filter(Boolean)

    if (!isEmpty(changedCalls)) {
      await dispatch(patchPlaylist(playlist.uuid, { playlist_calls: changedCalls }))
      await dispatch(fetchPlaylist(playlist.uuid))
    }
  }

  const handleRemoveCallFromPlaylist = (callId) => {
    dispatch(deletePlaylistCall(playlist.uuid, callId))
  }

  const handleToggleFlagged = (row) => {
    dispatch(patchPlaylistCall(playlist.uuid, row.call_id, { is_flagged: !row.is_flagged }))
  }

  const handleGoBack = () => {
    history.push('/playlists')
  }

  const handleEditAlias = (value, call) => {
    dispatch(patchPlaylistCall(playlist.uuid, call.call_id, { alias: value }))
  }

  const pauseAudio = () => {
    dispatch(pauseCallAudio())
  }

  const { callId: callIdListeningTo, progress: audioProgress } = useSelector(
    (state) => state.audio.callAudio
  )

  const generateCallExplorerLink = (callId) => {
    return `/call-explorer/${callId}?playlistUuid=${playlist.uuid}${callId === callIdListeningTo ? `&timestamp=${audioProgress}&autoplay=true` : ''}`
  }

  const columns = [
    {
      accessor: 'call_identifier',
      label: 'Call Identifier',
      isSearchable: true,
      isSortable: false,
    },
    {
      accessor: 'is_flagged',
      label: '',
      isSortable: false,
    },
    ...DEFAULT_CALL_COLUMNS.map((column) => ({ ...column, isSortable: false })),
  ]

  const trimText = (text) => {
    return truncate(text, { length: 50, omission: '...' })
  }

  const buildChangeString = (change) => {
    if (!isEmpty(change.calls_added)) {
      return `Added: ${change.calls_added.join(', ')}`
    }
    if (!isEmpty(change.calls_removed)) {
      return `Removed: ${change.calls_removed.join(', ')}`
    }
    if (!isEmpty(change.name)) {
      return `Renamed this to: ${change.name}`
    }
    if (!isEmpty(change.calls_updated)) {
      return change.calls_updated.map((obj) => {
        const [key, value] = Object.entries(obj)[0]

        let valueStr = value
        if (value === 'Flagged') {
          valueStr = 'Reviewed'
        }
        if (value === 'Removed flag') {
          valueStr = 'Removed review'
        }
        return `${valueStr}: ${key}`
      })
    }
    return ''
  }

  const playlistChangesRows = playlistChanges?.map((change) => {
    const displayChanges = buildChangeString(change)

    const eventType =
      change.event_type === 'update_playlist_calls' ? 'Update Calls' : 'Update Playlist'

    return {
      ...change,
      created_at: formatTime(change.created_at),
      event_type: eventType,
      what_changed: {
        as: (
          <div>
            <Popup
              disabled={displayChanges.length < 50}
              inverted
              content={displayChanges}
              trigger={<div>{trimText(displayChanges)}</div>}
            />
          </div>
        ),
        value: displayChanges,
      },
      calls_added: !isEmpty(change.calls_added) ? change.calls_added.join(', ') : 'N/A',
      calls_removed: !isEmpty(change.calls_removed) ? change.calls_removed.join(', ') : 'N/A',
      calls_updated: !isEmpty(change.calls_updated) ? change.calls_updated.join(', ') : 'N/A',
    }
  })

  const actions = [
    {
      label: 'Delete',
      trigger: (row) => {
        return (
          <ButtonAndFormModal
            popup
            icon={<IconTrash />}
            buttonProps={{ basic: true, compact: true }}
            popupProps={{ position: 'top right' }}
            modalId={`playlists/delete/call/${row.call_id}`}
            modalTitle="Remove Call"
            modalProps={{ size: 'tiny' }}
            form={
              <ConfirmForm
                valueToSubmit={row.call_id}
                bodyText="Are you sure you want to remove this call? This action cannot be undone."
                danger
                primaryButtonText="Remove Call"
                handleSubmit={() => handleRemoveCallFromPlaylist(row.call_id)}
              />
            }
          />
        )
      },
    },
  ]

  const renderPlaylistPage = () => {
    if (isEmpty(playlist)) {
      return (
        <>
          <header className="data-grid-header page-header" data-testid="playlist-page-no-data">
            <div className="flex-align-center medium-gap">
              <h1>No Playlist Available</h1>
            </div>
          </header>
          <div className="empty-table wide">
            <NoData />
          </div>
        </>
      )
    }

    let rows = playlist?.playlist_calls?.map((call) => ({
      ...call,
      call_identifier: {
        cellProps: {
          className: 'collapsing',
        },
        value: call.alias || call.call_id,
        as: (
          <a
            className="table-link"
            href={generateCallExplorerLink(call.call_id)}
            onClick={pauseAudio}
          >
            <AudioPlayerButton call={call} />
            {call.alias || call.call_id}
          </a>
        ),
      },
    }))

    if (canEditPlaylist) {
      rows = playlist?.playlist_calls?.map((call) => ({
        ...call,
        call_identifier: {
          cellProps: {
            className: 'collapsing',
          },
          value: call.alias || call.call_id,
          as: (
            <EditPlaylistCallAlias
              key={call.call_id}
              playlistUuid={playlist.uuid}
              call={call}
              handleEditAlias={handleEditAlias}
              generateCallExplorerLink={generateCallExplorerLink}
              pauseAudio={pauseAudio}
            />
          ),
        },
        is_flagged: {
          cellProps: {
            className: 'collapsing compact-cell',
          },
          value: call.is_flagged,
          as: (
            <Popup
              inverted
              content={call.is_flagged ? 'Remove Reviewed Status' : 'Mark as Reviewed'}
              trigger={
                <Button
                  icon
                  compact
                  basic
                  className="svg-button"
                  onClick={() => handleToggleFlagged(call)}
                >
                  {call.is_flagged ? (
                    <IconCircleCheckFilled className="status-success" />
                  ) : (
                    <IconCircleDashedCheck />
                  )}
                </Button>
              }
            />
          ),
        },
      }))
    }

    return (
      <>
        <header
          className="data-grid-header page-header"
          data-testid={isCoachingPage ? 'coaching-page' : 'playlist-page'}
        >
          {isCoachingPage ? (
            <h1>Coaching</h1>
          ) : (
            <div className="flex-align-center medium-gap">
              <Button secondary icon className="svg-button" onClick={handleGoBack}>
                <IconArrowLeft />
              </Button>
              <PlaylistName
                loading={loading.playlist}
                playlist={playlist}
                uuid={playlist.uuid}
                canEditPlaylist={canEditPlaylist}
              />
            </div>
          )}
        </header>
        <header className="data-grid-subheader flex-end">
          {!isEmpty(playlist?.playlist_calls) && !isEmpty(playlistChangesRows) && (
            <ButtonAndFormModal
              buttonLabel="Change History"
              buttonProps={{ secondary: true }}
              modalId={`playlists/audit/${playlist.uuid}`}
              modalProps={{ size: 'large' }}
              modalTitle="Change History"
              modalContent={
                <Segment className="not-padded">
                  <AdvancedTable
                    loading={loading.playlist}
                    rows={playlistChangesRows}
                    columns={auditColumns}
                    pagination
                    defaultOrder="desc"
                    sortEnabled={false}
                  />
                </Segment>
              }
            />
          )}
        </header>

        <AdvancedTable
          noDataHeader={
            isCoachingPage ? 'No Calls Available for Coaching' : 'No Calls in this Playlist'
          }
          noDataText={
            isCoachingPage
              ? 'Flag calls for coaching and they will appear on this page.'
              : 'Begin by adding calls to your new playlist.'
          }
          noDataAction={
            <Link to="/call-explorer" className="ui button primary">
              Explore Calls
            </Link>
          }
          dataGrid
          index={tableIndex}
          loading={loading.playlist}
          rows={rows}
          columns={columns}
          actions={canEditPlaylist ? actions : []}
          stickyAction
          pagination
          reorderable={canEditPlaylist}
          handleReorder={handleReorder}
          sortEnabled={false}
          striped={false}
          rowsPerPage={30}
        />

        <AudioDrawer />
      </>
    )
  }

  return renderPlaylistPage()
}
