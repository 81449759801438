export const UNCATEGORIZED_TAGS = 'Uncategorized Tags'

export const winOptions = [
  { value: true, label: 'Only wins' },
  { value: false, label: 'Only non-wins' },
]

export const scoredOptions = [
  { value: 'scored', label: 'Scored' },
  { value: 'unscored', label: 'Needs Scoring' },
  { value: 'any', label: 'All Statuses' },
]

export const groupByOptions = [
  { value: 'day', label: 'Daily' },
  { value: 'week', label: 'Weekly' },
  { value: 'month', label: 'Monthly' },
]

export const rankOnOptions = [
  { label: 'Checklist Usage', value: 'checklist_usage' },
  { label: 'Dynamic Prompt Usage', value: 'decklist_usage' },
  { label: 'Win Count', value: 'wins' },
  { label: 'Win Rate', value: 'win_rate' },
  { label: 'QA Score', value: 'qa' },
]

export const timePeriodOptions = [
  { value: 'today', label: 'Today' },
  { value: 'this_week', label: 'This Week' },
  { value: 'this_month', label: 'This Month' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'last_7_days', label: 'Last 7 days' },
  { value: 'last_14_days', label: 'Last 14 days' },
  { value: 'last_30_days', label: 'Last 30 days' },
]

export const minCallDurationOptions = [
  { value: '1', label: '1 minute or more' },
  { value: '2', label: '2 minutes or more' },
  { value: '5', label: '5 minutes or more' },
  { value: '10', label: '10 minutes or more' },
  { value: '15', label: '15 minutes or more' },
]

export const RTCDisplayGroupingOptions = [
  { value: 'day', label: 'Day', accessor: 'day' },
  { value: 'manager', label: 'Manager', accessor: 'manager' },
  { value: 'agent', label: 'Agent', accessor: 'agent' },
  { value: 'tag', label: 'Tag', accessor: 'tag' },
  { value: 'call_id', label: 'Call ID', accessor: 'call_id' },
  { value: 'playbook', label: 'Playbook', accessor: 'playbook' },
  { value: 'alert_type', label: 'Alert Type', accessor: 'alert_type' },
  { value: 'alert_name', label: 'Alert Name', accessor: 'alert_name' },
  { value: 'trigger', label: 'Trigger', accessor: 'trigger' },
]

export const notApplicableChartColor = '#DEDFE1'
export const chartColors = [
  '#58A6FF',
  '#FFBA67',
  '#2FB475',
  '#FAACAC',
  '#367ACD',
  '#B06CFF',
  '#03A29C',
  '#697CFF',
  '#F56E6E',
  '#2CE1CE',
  '#FFDB55',
  '#FF6BB1',
]

export const thresholdColors = ['#F78D8D', '#FCCCCC', '#FFDBB0', '#BCEBD2']

export const auditColumns = [
  {
    accessor: 'created_at',
    label: 'Created At',
  },
  {
    accessor: 'event_type',
    label: 'Event Type',
    collapsing: true,
    isSearchable: true,
  },
  {
    accessor: 'what_changed',
    label: 'What Changed',
    collapsing: true,
    isSearchable: true,
  },
  {
    accessor: 'created_by',
    label: 'Created By',
    isSearchable: true,
  },
]
