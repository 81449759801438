import moment from 'moment'
import queryString from 'query-string'
import { cloneDeep, isEmpty, isNaN, isNil, startCase, truncate } from 'lodash'
import pluralize from 'pluralize'

export const formatFilters = (filterValues = {}, section) => {
  const formattedFilterValues = cloneDeep(filterValues)
  formattedFilterValues.organization = filterValues?.organization
  formattedFilterValues.scorecards = filterValues?.scorecards
  formattedFilterValues.agents = filterValues.agents?.map((x) => x.value)
  formattedFilterValues.playbooks = filterValues.playbooks?.map((x) => x.value)
  formattedFilterValues.tags = filterValues.tags?.map((x) => `${x.tag_cat}:${x.value}`)
  formattedFilterValues.challengeType = filterValues.challengeType?.map((x) => x.value)
  formattedFilterValues.challengeStatus = filterValues.challengeStatus?.map((x) => x.value)
  formattedFilterValues.categories = ['deck', 'postcall'].includes(section)
    ? filterValues[`${section}Categories`]?.map((x) => x.value)
    : []
  formattedFilterValues.startDate = filterValues.startDate
    ? moment(filterValues.startDate).startOf('day').format()
    : undefined
  formattedFilterValues.endDate = filterValues.endDate
    ? moment(filterValues.endDate).endOf('day').format()
    : undefined
  formattedFilterValues.callDuration = isEmpty(filterValues?.callDuration)
    ? 0
    : filterValues.callDuration
  formattedFilterValues.maxCallDuration = isEmpty(filterValues?.maxCallDuration)
    ? 0
    : filterValues.maxCallDuration
  formattedFilterValues.sections = filterValues.sections
  formattedFilterValues.dispositions = isEmpty(filterValues?.dispositions)
    ? []
    : filterValues.dispositions

  const queryStringObject = {
    organization: formattedFilterValues.organization,
    // new reporting service uses this instead of "organization" once everything is moved deprecate "organization"
    organization_id: formattedFilterValues.organization,
    agents: formattedFilterValues.agents,
    playbooks: formattedFilterValues.playbooks,
    tags: formattedFilterValues.tags,
    categories: formattedFilterValues.categories,
    call_duration: formattedFilterValues.callDuration,
    max_call_duration: formattedFilterValues.maxCallDuration,
    include_managers: formattedFilterValues.includeManagers,
    type: formattedFilterValues.challengeType,
    status: formattedFilterValues.challengeStatus,
    start_date: formattedFilterValues.startDate,
    end_date: formattedFilterValues.endDate,
    sections: formattedFilterValues.sections,
    is_win: formattedFilterValues.isWin,
    dispositions: formattedFilterValues.dispositions.map(({ value }) => value),
  }
  // new reporting service does not tolerate nulls/empty string for these if not passed (not org 1 doesn't provide)
  if (!formattedFilterValues.organization) {
    delete queryStringObject.organization
    delete queryStringObject.organization_id
  }

  if (formattedFilterValues.isWin === null || section === 'usage' || section === 'csv') {
    delete queryStringObject.is_win
  }

  if (isEmpty(formattedFilterValues.dispositions || section === 'usage' || section === 'csv')) {
    delete queryStringObject.dispositions
  }

  const formattedQueryString = queryString.stringify(queryStringObject)

  return formattedQueryString
}

export const getOrganizationOptions = (organizations = [], isTruncated = false) => {
  return organizations
    .map((organization) => ({
      value: organization.id,
      label: isTruncated ? truncate(organization.name, { length: 35 }) : organization.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

export const getHierarchyOrganizationOptions = (organizations = [], isTruncated = false) => {
  return organizations
    .map(({ name, organization_id }) => ({
      value: organization_id,
      label: isTruncated ? truncate(name, { length: 35 }) : name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

export const getScorecardOptions = (scorecards) => {
  if (scorecards) {
    return scorecards.map((scorecard) => ({
      value: scorecard.id,
      label: scorecard.name,
      sid: scorecard.sid, // Needed for aggregate endpoint
    }))
  }
  return []
}

export const getSectionOptions = (selectedScorecard) => {
  if (selectedScorecard) {
    return selectedScorecard.sections?.map((section) => ({
      value: section.id,
      label: section.name,
    }))
  }
  return []
}

export const getNestedOptions = (selectedOption, sectionName) => {
  const nestedOption = []
  if (selectedOption?.length > 0) {
    selectedOption.forEach((section) => {
      const nestedObject = {
        label: section.name,
        options: [],
      }
      const formatObject = section[sectionName]?.map((measure) => {
        return {
          value: measure.id,
          label: measure.name,
        }
      })
      nestedObject.options.push(...formatObject)
      nestedOption.push(nestedObject)
    })
  }
  return nestedOption
}

export const getTagOptions = (tags) => {
  return tags
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((tag) => ({
      label: tag.name,
      value: tag.id,
      tag_cat: tag.tag_category_id,
    }))
}

export const getTagsWithCategories = (tags, tagCategories) => {
  const tagsWithCategories = []

  // Created grouped tag categories
  tagCategories.forEach((cat) => {
    tagsWithCategories.push({
      label: cat.name,
      options: getTagOptions(tags.filter((tag) => tag.tag_category_id === cat.id)),
    })
  })

  // Add uncategorized tags
  tagsWithCategories.push({
    label: 'Uncategorized',
    options: getTagOptions(tags.filter((tag) => !tag.tag_category_id)),
  })

  return tagsWithCategories
}

export const getDateFormat = (date, groupByValue) => {
  switch (groupByValue) {
    case 'week':
      return `${moment.utc(date).format('M/DD')} - ${moment
        .utc(date)
        .add(6, 'days')
        .format('M/DD')}`
    case 'month':
      return moment.utc(date).format('MMMM')
    default:
      return moment.utc(date).format('M/DD')
  }
}

export function getPercentNumerical(numerator, denominator) {
  if (denominator > 0) {
    return Math.round(Math.abs((numerator / denominator) * 100))
  }

  return 0
}

export const formatPercent = (value, decimals, lessThanOne = false) => {
  if (isNaN(value) || value === 0) {
    return '0%'
  }

  if (decimals) {
    if (value < 1 && lessThanOne) {
      return '< 1%'
    }
    return `${parseFloat(value).toFixed(decimals)}%`
  }

  return `${Math.round(Math.abs(value))}%`
}

export const formatSeconds = (seconds) => {
  const mins = Math.floor(seconds / 60)
  const secs = Math.floor(seconds - mins * 60)

  return `${mins}m ${secs}s`
}

export const getChecklistPercent = (checklistData) => {
  if (checklistData.length) {
    const checklistTotals = checklistData.reduce((a, b) => {
      // eslint-disable-next-line no-return-assign, no-param-reassign
      Object.keys(b).map((c) => (a[c] = (a[c] || 0) + b[c]))

      return a
    }, {})

    if (Object.keys(checklistTotals).length > 0 && checklistTotals.total > 0) {
      return getPercentNumerical(checklistTotals.items, checklistTotals.total)
    }
  }

  return 0
}

export function explodeItemsByUser(items, users) {
  const agents = users.reduce((acc, item) => {
    const totalCalls = item?.total_calls || 0
    const totalUsedEvents = item?.total_used_events || 0
    const totalPossibleEvents = item?.total_possible_events || 0

    acc[item.id] = {
      first_name: item?.first_name,
      last_name: item?.last_name,
      ...(totalCalls !== 0 && {
        total_calls_count: totalCalls,
      }),
      ...(totalCalls !== 0 && {
        average_completion_percent: getPercentNumerical(totalUsedEvents, totalPossibleEvents),
      }),
    }
    return acc
  }, {})

  return items.flatMap((item) =>
    item.users.map((userEntry) => ({
      item: item.item,
      count: userEntry?.num_calls_with_event || 0,
      total: userEntry?.total_calls || 0,
      user: agents[userEntry.id],
    }))
  )
}

export function truncateString(string, num) {
  if (string.length > num) {
    // eslint-disable-next-line prefer-template
    return string.slice(0, num - 3) + '...'
  }
  return string
}

export const truncateAgentName = (agentName) => {
  if (agentName) {
    const agentFirstInitial = agentName.split(' ')[0].charAt(0)
    return `${agentFirstInitial}. ${agentName.split(' ')[1]}`
  }
  return false
}

export const compareToPreviousRange = (currentData, prevData) => {
  if (!prevData) {
    return null
  }

  if (currentData === prevData) {
    return 0
  }

  const result = (Math.abs(currentData - prevData) / prevData) * 100
  const isDecimal = Math.ceil(result) !== result

  return isDecimal ? result.toFixed(2) : result
}

export const findPreviousDateRangeFromCurrentFilters = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return [startDate, endDate]
  }

  const parsedStartDate = new Date(startDate)
  const parsedEndDate = new Date(endDate)
  const numDays = parsedEndDate - parsedStartDate || 86400000 // number of milliseconds in a day, because default is one day
  const newStartDate = new Date(parsedStartDate - numDays)
  const newEndDate = new Date(parsedEndDate - numDays)

  return [newStartDate, newEndDate]
}

export const getCardVariant = (currentData, prevData) => {
  if (currentData > prevData) {
    return 'positive'
  }

  if (currentData < prevData) {
    return 'negative'
  }

  return 'neutral'
}

export const getCardVariantReverse = (currentData, prevData) => {
  if (currentData < prevData) {
    return 'positive'
  }

  if (currentData > prevData) {
    return 'negative'
  }

  return 'neutral'
}

// FYI, `is_default` is totally different than `filterType === default`. Have fun with that!
export const formatSavedFilters = (filter, currentUser) => ({
  name: filter.name,
  is_default: filter.isDefault,
  filter_type: filter.filterType,
  filters: {
    organization: currentUser.organizationid,
    agents: filter.agents,
    playbooks: filter.playbooks,
    deckCategories: filter.deckCategories,
    postcallCategories: filter.postcallCategories,
    tags: filter.tags,
    includeManagers: filter.includeManagers,
    callDuration: filter.filterType !== 'default' ? undefined : parseInt(filter.callDuration, 10),
    maxCallDuration: filter.maxCallDuration,
    dateRange: filter.dateRange,
    ignoreWeekends: filter.ignoreWeekends,
    timePeriod: filter.timePeriod,
  },
})

const getAvg = (arr) => {
  const total = arr.reduce((acc, c) => acc + c, 0)
  return total / arr.length
}

const getSum = (arr) => {
  return arr.reduce((acc, c) => acc + c, 0)
}
// https://github.com/heofs/trendline stolen from this
// data science confirmed math is good
export const createTrend = (data, xKey, yKey) => {
  const xData = data.map((value) => value[xKey])
  const yData = data.map((value) => value[yKey])

  const xMean = getAvg(xData)
  const yMean = getAvg(yData)

  const xMinusxMean = xData.map((val) => val - xMean)
  const yMinusyMean = yData.map((val) => val - yMean)

  const xMinusxMeanSq = xMinusxMean.map((val) => val ** 2)

  const xy = []
  for (let x = 0; x < data.length; x++) {
    xy.push(xMinusxMean[x] * yMinusyMean[x])
  }

  const xySum = getSum(xy)

  const slope = xySum / getSum(xMinusxMeanSq)
  const yStart = yMean - slope * xMean

  return {
    slope,
    yStart,
    calcY: (xValue) => yStart + slope * xValue,
  }
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const formatTime = (time, formatString = 'MMMM D, YYYY [at] h:mm A') => {
  return moment.utc(time).local().format(formatString)
}

export const paramPusher = (filterValues, params, data, key, property = 'value') => {
  if (!isEmpty(filterValues[data])) {
    filterValues[data].forEach((item) => params.append(key, item[property]))
  }
}

export const sharedScorecardParams = (
  filterTypes,
  filterValues,
  params,
  orgId,
  isCopilot = false
) => {
  if (typeof orgId === 'number') {
    params.set('org_id', orgId)
    params.set('requested_organization_id', orgId)
  }

  if (isCopilot) {
    params.set('scorecard_type', 'copilot')
  }

  if (filterValues.scorecardType) {
    params.set('scorecard_type', filterValues.scorecardType)
  }

  if (!isNil(filterValues.isWin)) {
    params.set('is_win', filterValues.isWin)
  }

  if (!isNil(filterValues.scoredStatus)) {
    params.set('scored_status', filterValues.scoredStatus)
  }

  if (filterValues.startDate) {
    params.set('start_date', moment(filterValues.startDate).utc().format())
  }

  if (filterValues.endDate) {
    params.set('end_date', moment(filterValues.endDate).utc().format())
  }

  filterTypes.forEach((filter) =>
    paramPusher(filterValues, params, filter.data, filter.key, filter.value)
  )
}

export const getPlaceholderContent = (data, loading, type) => {
  if (loading[type]) {
    return { placeholder: 'Loading...' }
  }

  if (!isEmpty(data[type])) {
    return { placeholderPill: `All ${startCase(pluralize(type))}` }
  }

  return { placeholder: 'None available' }
}

export const getRangeInDays = (startDate, endDate) => {
  const duration = moment.duration(moment(endDate).diff(moment(startDate)))
  const days = Math.floor(duration.asDays())

  return days
}

export const getTickValuesForDateRange = (days) => {
  if (days < 7) {
    return 'every day'
  }

  if (days < 31) {
    return 'every 5 days'
  }

  if (days < 61) {
    return 'every week'
  }

  return 'every week'
}

export const convertAgentNameToInitials = (agentName) => {
  return agentName
    .match(/(\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toUpperCase()
}
