import format from 'date-fns/format'

const startOfToday = format(new Date(), "yyyy-MM-dd'T00:00:00'xxx")
const endOfToday = format(new Date(), "yyyy-MM-dd'T23:59:59'xxx")

// Action Types
export const SET_CALLS = 'callSearch/setCalls'
export const SET_CALL_EXPLORER_CALL = 'callSearch/setCallExplorerCall'
export const SET_CALL_EXPLORER_DATA = 'callSearch/setCallExplorerData'
export const SET_DATA = 'callSearch/setData'
export const SET_FILTERS = 'callSearch/setFilters'
export const SET_DATE_RANGE_FILTERS = 'callSearch/setDateRangeFilters'
export const SET_LOADING = 'callSearch/setLoading'
export const SET_ERROR = 'callSearch/setError'
export const SET_CALL_EXPLORER_LOADING = 'callSearch/setCallExplorerLoading'
export const SET_CALL_EXPLORER_MORE_OPTIONS = 'callSearch/setCallExplorerMoreOptions'

// Action Creators
export const setCalls = (payload) => ({ type: SET_CALLS, payload })
export const setCallExplorerCall = (payload) => ({ type: SET_CALL_EXPLORER_CALL, payload })
export const setCallExplorerData = (payload) => ({ type: SET_CALL_EXPLORER_DATA, payload })
export const setData = (payload) => ({ type: SET_DATA, payload })
export const setFilters = (payload) => ({ type: SET_FILTERS, payload })
export const setDateRangeFilters = (payload) => ({ type: SET_DATE_RANGE_FILTERS, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setError = (payload) => ({ type: SET_ERROR, payload })
export const setCallExplorerLoading = (payload) => ({ type: SET_CALL_EXPLORER_LOADING, payload })
export const setCallExplorerMoreOptions = (payload) => ({ type: SET_CALL_EXPLORER_MORE_OPTIONS, payload}) // prettier-ignore

// State
export const initialState = {
  calls: [],
  showMoreOptions: false,
  callExplorer: {
    callId: '',
    analysis: [],
    analysisFlattened: [],
    metadata: {},
    transcript: [],
    selectedPillTimeline: null,
    selectedPillIndex: null,
    softSelectedEvents: [],
    hardSelectedEvent: null,
    audioUrl: '',
    audioError: false,
    audioUrlExpiration: 0,
    audioDuration: 0,
    audioProgress: 0,
    audioPlaying: false,
    audioPlaybackRate: 1,
    audioStatus: 'not_available',
    screenCaptureUrls: [],
    screenCaptureError: false,
    screenCaptureStatus: 'not_available',
    groupedEvents: {},
    numIntervals: 0,
    qaScores: [],
    callSummary: {},
    copilotAnchors: [],
    associatedCallIds: [],
    selectedCriteriaFilter: 'All Criteria',
    selectedCriteriaFocusIndex: 0,
  },
  data: {
    agents: [],
    tags: [],
    playbooks: [],
    playbookData: {},
    voipCustomerCalls: [],
    dispositions: [],
  },
  filters: {
    startDate: startOfToday,
    endDate: endOfToday,
    organizationId: '',
    agents: [],
    tags: [],
    playbooks: [],
    callDuration: '1',
    maxCallDuration: '',
    isWin: '',
    includeCallsWithoutAudio: false,
    keywords: [],
    keywordOptions: {
      side: 'both',
      logic: 'or',
    },
    checklist: {
      selected: [],
      includes: true,
    },
    deck: {
      selected: [],
      includes: true,
    },
    postcall: {
      selected: [],
      includes: true,
    },
    notifications: {
      selected: [],
      includes: true,
    },
    openCategories: [],
    openSubcategories: [],
    dispositions: [],
    scorecardConfigScids: [],
    scorecardType: '',
    threshold: '',
  },
  loading: {
    calls: false,
    tags: false,
    playbooks: false,
    playbookData: false,
    qaScores: false,
    callSummary: false,
    dispositions: false,
  },
  callExplorerLoading: false,
  error: {
    callExplorer: false,
  },
  selectedRows: [],
}

export default function callSearchReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CALLS:
      return { ...state, calls: action.payload }
    case SET_CALL_EXPLORER_CALL:
      return { ...state, callExplorer: { ...initialState.callExplorer, ...action.payload } }
    case SET_CALL_EXPLORER_DATA:
      return { ...state, callExplorer: { ...state.callExplorer, ...action.payload } }
    case SET_DATA:
      return { ...state, data: { ...state.data, ...action.payload } }
    case SET_FILTERS:
      return { ...state, filters: { ...state.filters, ...action.payload } }
    case SET_DATE_RANGE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      }
    case SET_LOADING:
      return { ...state, loading: { ...state.loading, ...action.payload } }
    case SET_ERROR:
      return { ...state, error: { ...state.error, ...action.payload } }
    case SET_CALL_EXPLORER_LOADING:
      return { ...state, callExplorerLoading: action.payload }
    case SET_CALL_EXPLORER_MORE_OPTIONS:
      return { ...state, showMoreOptions: action.payload }
    default:
      return state
  }
}
