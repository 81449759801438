import produce from 'immer'

// Action Types
export const SET_PLAYLISTS = 'playlists/setPlaylists'
export const SET_PLAYLISTS_CHANGES = 'playlists/setPlaylistsChanges'

export const SET_PLAYLIST = 'playlists/setPlaylist'
export const SET_PLAYLIST_CHANGES = 'playlists/setPlaylistChanges'
export const SET_LOADING = 'playlists/setLoading'
export const SET_PLAYLISTS_BY_CALL_ID = 'playlists/setPlaylistsByCallId'

// Action Creators
export const setPlaylists = (payload) => ({ type: SET_PLAYLISTS, payload })
export const setPlaylistsChanges = (payload) => ({ type: SET_PLAYLISTS_CHANGES, payload })
export const setPlaylist = (payload) => ({ type: SET_PLAYLIST, payload })
export const setPlaylistChanges = (payload) => ({ type: SET_PLAYLIST_CHANGES, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setPlaylistsByCallId = (payload) => ({ type: SET_PLAYLISTS_BY_CALL_ID, payload })

// State
export const initialState = {
  // Individual
  playlist: null,
  playlistChanges: [],

  // List
  playlists: [],
  playlistsChanges: [],
  playlistsByCallId: [],

  loading: {
    playlist: false,
    playlists: false,
    playlistsByCallId: false,
  },
}

// Reducer
export default function playlistReducer(state = initialState, action) {
  return produce(state, (newState) => {
    const { payload } = action

    switch (action.type) {
      case SET_PLAYLISTS:
        newState.playlists = payload
        break
      case SET_PLAYLISTS_CHANGES:
        newState.playlistsChanges = payload
        break
      case SET_PLAYLIST:
        newState.playlist = payload
        break
      case SET_PLAYLIST_CHANGES:
        newState.playlistChanges = payload
        break
      case SET_LOADING:
        newState.loading = { ...state.loading, ...payload }
        break
      case SET_PLAYLISTS_BY_CALL_ID:
        newState.playlistsByCallId = payload
        break
      default:
        break
    }
  })
}
