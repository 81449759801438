import produce from 'immer'

import { DISPUTES_TABLE_NAME, CALL_SEARCH_TABLE_NAME } from './customColumns.constants'

// Action Types
export const SET_CUSTOM_COLUMNS = 'customColumns/setCustomColumns'
export const SET_LOADING = 'customColumns/setLoading'

// Action Creators
export const setCustomColumns = (payload) => ({ type: SET_CUSTOM_COLUMNS, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })

// State
export const initialState = {
  customColumns: {
    [DISPUTES_TABLE_NAME]: [],
    [CALL_SEARCH_TABLE_NAME]: [],
  },
  loading: {
    [DISPUTES_TABLE_NAME]: false,
    [CALL_SEARCH_TABLE_NAME]: false,
  },
}

// Reducer
export default function customColumnsReducer(state = initialState, action) {
  return produce(state, (newState) => {
    const { payload } = action

    switch (action.type) {
      case SET_CUSTOM_COLUMNS:
        newState.customColumns = { ...state.customColumns, ...payload }
        break
      case SET_LOADING:
        newState.loading = { ...state.loading, ...payload }
        break
      default:
        break
    }
  })
}
