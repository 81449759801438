import React from 'react'
import { Link } from 'react-router-dom'
import pluralize from 'pluralize'
import queryString from 'query-string'
import { toast } from 'react-toastify'

import { apiService, fetchingAPI } from '@/api'

import { isEmpty } from 'lodash'
import * as redux from './playlists.redux'

export const fetchPlaylists = (organizationId, includeCoaching = false) => {
  const params = queryString.stringify({
    requested_organization_id: organizationId,
    include_coaching: includeCoaching,
  })

  return async (dispatch) => {
    dispatch(redux.setLoading({ playlists: true }))

    try {
      const [playlistsResponse, playlistsChangesResponse] = await Promise.all([
        fetchingAPI(`${apiService.playlist}/playlists?${params}`, 'GET', dispatch),
        fetchingAPI(`${apiService.playlist}/history?${params}`, 'GET', dispatch),
      ])

      dispatch(redux.setPlaylists(playlistsResponse))
      dispatch(redux.setPlaylistsChanges(playlistsChangesResponse))
    } catch (err) {
      toast.error('Failed to fetch playlists')
    } finally {
      dispatch(redux.setLoading({ playlists: false }))
    }
  }
}

export const fetchPlaylist = (playlistUuid) => {
  return async (dispatch) => {
    dispatch(redux.setLoading({ playlist: true }))

    try {
      const [playlistResponse, playlistChangesResponse] = await Promise.all([
        fetchingAPI(`${apiService.playlist}/playlists/${playlistUuid}`, 'GET', dispatch),
        fetchingAPI(`${apiService.playlist}/history/${playlistUuid}`, 'GET', dispatch),
      ])

      let calls = []

      if (!isEmpty(playlistResponse.playlist_calls)) {
        const callsResponse = await fetchingAPI(
          `${apiService.reporting}/api/calls`,
          'POST',
          dispatch,
          JSON.stringify({
            call_ids: playlistResponse.playlist_calls.map((value) => value.call_id),
            organization_id: playlistResponse.organization_id,
          })
        )
        calls = callsResponse.calls
      }

      const playlist = {
        ...playlistResponse,
        playlist_calls: playlistResponse.playlist_calls.map((value) => {
          const callData = calls.find((call) => call.call_id === value.call_id)

          return { ...callData, ...value }
        }),
      }

      dispatch(redux.setPlaylist(playlist))
      dispatch(redux.setPlaylistChanges(playlistChangesResponse))
    } catch (err) {
      toast.error('Failed to fetch playlist')
      dispatch(redux.setPlaylist(null))
      dispatch(redux.setPlaylistChanges([]))
    } finally {
      dispatch(redux.setLoading({ playlist: false }))
    }
  }
}

export const createPlaylist = (name, callIds = []) => {
  return async (dispatch, getState) => {
    const { currentUser } = getState()
    const body = JSON.stringify({
      name,
      playlist_calls: callIds.map((callId) => ({ call_id: callId })),
    })

    try {
      const response = await fetchingAPI(`${apiService.playlist}/playlists`, 'POST', dispatch, body)

      await dispatch(fetchPlaylists(currentUser.organizationid))

      toast.success(
        <div>
          <p>Playlist created</p>
          <p>
            <Link to={`/playlists/${response.uuid}`}>View Playlist</Link>
          </p>
        </div>
      )
    } catch (err) {
      toast.error('Failed to create playlist')
    }
  }
}

export const patchPlaylist = (playlistUuid, values) => {
  return async (dispatch) => {
    try {
      const body = JSON.stringify(values)

      await fetchingAPI(`${apiService.playlist}/playlists/${playlistUuid}`, 'PATCH', dispatch, body)
      // Must do your own fetching after this because sometimes it's used on the "playlist" page and sometimes on the "playlists" page

      toast.success('Playlist updated')
    } catch (err) {
      toast.error('Failed to update playlist')
    }
  }
}

export const addCallsToPlaylist = (playlistUuid, callIds = [], isCoachingPlaylist) => {
  return async (dispatch) => {
    try {
      const body = JSON.stringify({
        playlist_calls: callIds.map((callId) => ({ call_id: callId })),
      })

      await fetchingAPI(`${apiService.playlist}/playlists/${playlistUuid}`, 'PATCH', dispatch, body)

      if (isCoachingPlaylist) {
        toast.success(
          <div>
            <p>{`${pluralize('Call', callIds.length)} flagged for coaching`}</p>
            <p>
              <Link to="/coaching" data-testid="toast-playlist-link">
                View Coaching List
              </Link>
            </p>
          </div>
        )
      } else {
        toast.success(
          <div>
            <p>{`${pluralize('Call', callIds.length)} added to playlist.`}</p>
            <p>
              <Link to={`/playlists/${playlistUuid}`} data-testid="toast-playlist-link">
                View Playlist
              </Link>
            </p>
          </div>
        )
      }
    } catch (err) {
      toast.error('Failed to add calls')
    }
  }
}

export const deletePlaylistCall = (playlistUuid, callId) => {
  return async (dispatch) => {
    try {
      await fetchingAPI(
        `${apiService.playlist}/playlists/${playlistUuid}/playlist_call/${callId}/`,
        'DELETE',
        dispatch
      )
      await dispatch(fetchPlaylist(playlistUuid))
      toast.success('Call removed')
    } catch (err) {
      toast.error('Failed to remove call')
    }
  }
}

export const patchPlaylistCall = (playlistUuid, callId, value) => {
  return async (dispatch) => {
    try {
      const body = JSON.stringify({
        uuid: playlistUuid,
        playlist_calls: [{ call_id: callId, ...value }],
      })

      await fetchingAPI(`${apiService.playlist}/playlists/${playlistUuid}`, 'PATCH', dispatch, body)
      await dispatch(fetchPlaylist(playlistUuid))
      toast.success('Call updated')
    } catch (err) {
      toast.error('Failed to update call')
    }
  }
}

export const deletePlaylist = (playlistUuid, organizationId) => {
  return async (dispatch) => {
    try {
      await fetchingAPI(`${apiService.playlist}/playlists/${playlistUuid}`, 'DELETE', dispatch)
      await dispatch(fetchPlaylists(organizationId))
      toast.success('Playlist deleted')
    } catch (err) {
      toast.error('Failed to delete playlist')
    }
  }
}

export const fetchPlaylistsByCallId = (callId) => {
  return async (dispatch) => {
    try {
      dispatch(redux.setLoading({ playlistsByCallId: true }))
      const response = await fetchingAPI(
        `${apiService.playlist}/playlists/by_call_id/${callId}`,
        'GET',
        dispatch
      )

      dispatch(redux.setPlaylistsByCallId(response))
    } catch (err) {
      toast.error('Failed to fetch associated playlists')
    } finally {
      dispatch(redux.setLoading({ playlistsByCallId: false }))
    }
  }
}

export const fetchCoachingPlaylist = (organizationId) => {
  const params = queryString.stringify({
    requested_organization_id: organizationId,
    include_coaching: true,
  })

  return async (dispatch) => {
    try {
      dispatch(redux.setLoading({ playlist: true }))

      const playlists = await fetchingAPI(
        `${apiService.playlist}/playlists?${params}`,
        'GET',
        dispatch
      )

      const coachingPlaylist = playlists.find((playlist) => playlist.type === 'coaching')

      if (coachingPlaylist) {
        const [playlistResponse, playlistChangesResponse] = await Promise.all([
          fetchingAPI(`${apiService.playlist}/playlists/${coachingPlaylist.uuid}`, 'GET', dispatch),
          fetchingAPI(`${apiService.playlist}/history/${coachingPlaylist.uuid}`, 'GET', dispatch),
        ])

        let calls = []

        if (!isEmpty(playlistResponse.playlist_calls)) {
          const callsResponse = await fetchingAPI(
            `${apiService.reporting}/api/calls`,
            'POST',
            dispatch,
            JSON.stringify({
              call_ids: playlistResponse.playlist_calls.map((value) => value.call_id),
              organization_id: playlistResponse.organization_id,
            })
          )
          calls = callsResponse.calls
        }

        const playlist = {
          ...playlistResponse,
          playlist_calls: playlistResponse.playlist_calls.map((value) => {
            const callData = calls.find((call) => call.call_id === value.call_id)

            return { ...callData, ...value }
          }),
        }

        dispatch(redux.setPlaylist(playlist))
        dispatch(redux.setPlaylistChanges(playlistChangesResponse))
      } else {
        dispatch(redux.setPlaylist(null))
        dispatch(redux.setPlaylistChanges([]))
      }
    } catch (err) {
      toast.error('Failed to fetch coaching playlist')
      dispatch(redux.setPlaylist(null))
      dispatch(redux.setPlaylistChanges([]))
    } finally {
      dispatch(redux.setLoading({ playlist: false }))
    }
  }
}
