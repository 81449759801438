import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Button, Loader } from 'semantic-ui-react'
import { IconX } from '@tabler/icons-react'

import {
  toggleCallAudioPlaying,
  updateCallAudio,
  resetCallAudio,
  updateSettings,
} from '@/reducers/audio/audio.redux'
import { audioErrorSwitch } from '@/utils/helpers'
import { SKIP_MODIFIER } from '@/utils/constants'
import '@/views/Playlists/components/BottomDrawer.scss'

import { SkipButton } from './components/SkipButton'
import { PlayPauseButton } from './components/PlayPauseButton'
import { PlaybackRateDropdown } from './components/PlaybackRateDropdown'
import { AudioTrackTime } from './components/AudioTrackTime'

export const AudioDrawer = () => {
  const dispatch = useDispatch()
  const { loading, callAudio, settings } = useSelector((state) => state.audio)
  const { progress, duration, audioUrl, audioError, isPlaying } = callAudio
  const { playbackRate } = settings
  const [isHidden, setIsHidden] = useState(!isPlaying)
  const audioRef = useRef()
  const trackProgressRef = useRef()
  const isDisabled = !audioUrl || audioError

  const handleForwardSkip = () => {
    const canScrubForward = progress + SKIP_MODIFIER <= duration

    if (canScrubForward) {
      dispatch(updateCallAudio({ progress: progress + SKIP_MODIFIER }))
      audioRef.current.currentTime += SKIP_MODIFIER
    } else {
      // Max progress is the duration as an integer, so this just sets to the end of the range
      dispatch(updateCallAudio({ progress: duration }))
      audioRef.current.currentTime = duration
    }
  }

  const handleBackSkip = () => {
    const canScrubBack = progress - SKIP_MODIFIER >= 0

    if (canScrubBack) {
      dispatch(updateCallAudio({ progress: progress - SKIP_MODIFIER }))
      audioRef.current.currentTime -= SKIP_MODIFIER
    } else {
      dispatch(updateCallAudio({ progress: 0 }))
      audioRef.current.currentTime = 0.0
    }
  }

  const handleAudioPlaybackRateChange = (event, option) => {
    const updatedPlaybackRate = parseFloat(option.value)

    localStorage.setItem('audioPlaybackRate', updatedPlaybackRate)

    dispatch(updateSettings({ playbackRate: updatedPlaybackRate }))
    audioRef.current.playbackRate = updatedPlaybackRate
  }

  const togglePlayState = () => {
    dispatch(toggleCallAudioPlaying())
  }

  const stopProgressTracking = () => {
    clearInterval(trackProgressRef.current)
    trackProgressRef.current = null
  }

  const startProgressTracking = () => {
    // Clear any timers already running
    stopProgressTracking()

    trackProgressRef.current = setInterval(() => {
      if (audioRef.current) {
        if (audioRef.current.ended) {
          dispatch(updateCallAudio({ isPlaying: false }))
        } else {
          dispatch(updateCallAudio({ progress: audioRef.current.currentTime }))
        }
      }
    }, [50])
  }

  const handleCloseDrawer = () => {
    dispatch(resetCallAudio())
    stopProgressTracking()
    setIsHidden(true)
  }

  useEffect(() => {
    // Show player, play, and set duration if playing
    if (isPlaying && audioUrl) {
      setIsHidden(false)

      audioRef.current.play()
      startProgressTracking()
    }

    // Pause if audio is loaded but not playing
    if (!isPlaying) {
      audioRef.current.pause()
      stopProgressTracking()
    }
  }, [isPlaying])

  useEffect(() => {
    const setDurationListener = (event) => {
      dispatch(updateCallAudio({ duration: Math.floor(event.target.duration) }))
    }

    const errorListener = () => {
      audioErrorSwitch(audioRef.current.error.code)

      dispatch(updateCallAudio({ audioError: true }))
    }

    audioRef.current.addEventListener('loadedmetadata', setDurationListener)
    audioRef.current.addEventListener('error', errorListener)

    const storedPlaybackRate = localStorage.getItem('audioPlaybackRate')
    if (storedPlaybackRate !== playbackRate) {
      dispatch(updateSettings({ playbackRate: parseFloat(storedPlaybackRate) }))
      audioRef.current.playbackRate = storedPlaybackRate
    }

    return () => {
      audioRef.current.removeEventListener('loadedmetadata', setDurationListener)
      audioRef.current.removeEventListener('error', errorListener)
      stopProgressTracking()
    }
  }, [audioUrl])

  return (
    <div
      data-testid="audio-drawer"
      className={classNames('bottom-drawer audio-drawer', { hidden: isHidden })}
    >
      <audio src={audioUrl} ref={audioRef} />
      <div className="drawer-header">
        {loading.callAudio ? (
          <Loader inline active />
        ) : (
          <>
            <div className="flex-align-center gap">
              <PlayPauseButton
                isPlaying={isPlaying}
                togglePlayState={togglePlayState}
                isDisabled={isDisabled}
              />
              <div className="flex-align-center">
                <SkipButton
                  modifier={SKIP_MODIFIER}
                  handleSkip={handleBackSkip}
                  isDisabled={isDisabled}
                />
                <SkipButton
                  modifier={SKIP_MODIFIER}
                  handleSkip={handleForwardSkip}
                  isForwardSkip
                  isDisabled={isDisabled}
                />
              </div>
            </div>
            <AudioTrackTime duration={duration} progress={progress} divider />
            <div className="flex-align-center small-gap">
              <PlaybackRateDropdown
                isDisabled={isDisabled}
                handleAudioPlaybackRateChange={handleAudioPlaybackRateChange}
                audioPlaybackRate={playbackRate}
              />
              <Button
                compact
                icon
                basic
                type="button"
                className="svg-button"
                onClick={handleCloseDrawer}
              >
                <IconX />
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
